
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  ref,
} from "vue";
import { Api } from "@/assets/Api";
import { ElMessage, ElMessageBox } from "element-plus";
import ChaKanTouSu from "./TouSu.vue";
//import RegisterTime from "./RegisterTime.vue";

interface Column {
  label: string;
  prop: string;
  search: boolean;
  regex: boolean;
  rules: [];
}
export default defineComponent({
  name: "ConfigTouSu",
  props: ["dialogFormVisible"],
  emits: ["close"],
  components: { ChaKanTouSu },
  setup(props, { emit }) {
    let data = reactive<any>({
      init: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      url: "",
      maps: new Map(),
      exchangeList:[],
      selectform: {
        Phone: "",
      },
    });
    const selectExchange = (item: any) => {      
        if(data.selectform.Phone == ""){
            ElMessage.warning("请输入用户号码");
            return;
        }
        data.exchangeList = []
        Api.Config.GetExchangeList(data.selectform.Phone).then((res: any) => {
        console.log("res");
        console.log(res);
        
        if (res.Code == 0) {  
            for(var i = 0; i < res.Data.length; i++){
                res.Data[i].CreatedAt = res.Data[i].CreatedAt.replace(/T/g, ' ').replace(/.[\d]{3}Z/, ' ').substring(0, 19);
                if(res.Data[i].Status == 0){
                    res.Data[i].Status = "订单处理中"
                }
                else if(res.Data[i].Status == 1){
                    res.Data[i].Status = "订单充值成功"
                }
                else if(res.Data[i].Status == 2){
                    res.Data[i].Status = "订单失败"
                }
                data.exchangeList.push(res.Data[i]);
            }
        } 
      });
            console.log(data.exchangeList);
    };
    const refresh = (item: any) => {
        ElMessageBox.confirm("确认要刷新兑换权益结果吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "",
      })
        .then(() => {
          Api.Config.updateExchange(item.OrderSn).then((res: any) => {
            if (res.Code == 0) {
              ElMessage.success("刷新成功");
            } else {
              ElMessage.warning({
                showClose: true,
                duration: 10000,
                message: res.ResultMsg,
              });
            }
          });
        })
        .catch(() => {
          console.log("刷新");
        });
    }
    // onMounted(() => {
    // });

    return {
      ...toRefs(data),
      selectExchange,
      refresh,
    };
  },
});
