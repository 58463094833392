import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form, {
      inline: true,
      model: _ctx.selectform,
      class: "demo-form-inline"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, { label: "用户号码" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.selectform.Phone,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectform.Phone) = $event)),
              placeholder: "请输入用户号码"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _ctx.selectExchange,
              icon: "el-icon-search"
            }, {
              default: _withCtx(() => [
                _createTextVNode("查询")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"]),
    _createVNode(_component_el_table, {
      ref: "table",
      data: _ctx.exchangeList,
      border: "",
      style: {"width":"100%"},
      height: "70vh",
      "row-key": "complaintFormId",
      stripe: "",
      "header-cell-style": {'text-align':'center'},
      "cell-style": {'text-align':'center'}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "Phone",
          label: "用户号码"
        }),
        _createVNode(_component_el_table_column, {
          prop: "Name",
          label: "兑换权益名称"
        }),
        _createVNode(_component_el_table_column, {
          prop: "CreatedAt",
          label: "兑换时间"
        }),
        _createVNode(_component_el_table_column, {
          prop: "Status",
          label: "充值结果状态"
        }),
        _createVNode(_component_el_table_column, {
          prop: "OrderSn",
          label: "订单编号"
        }),
        _createVNode(_component_el_table_column, { label: "操作" }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_button, {
              size: "small",
              type: "primary",
              link: "",
              onClick: ($event: any) => (_ctx.refresh(scope.row))
            }, {
              default: _withCtx(() => [
                _createTextVNode("刷新结果")
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"])
  ]))
}